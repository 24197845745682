<template>
    <v-row no-gutters align="center" class="txt txt--xs fill-height" :justify="justifySpaceBetween ? 'space-between' : undefined">
        <v-col cols="12" md="auto" class="d-flex align-center" v-bind="$attrs.headerProps">
            <div class="w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px">{{ term }} <span v-if="required" class="red--text">*</span></div>
        </v-col>
        <v-col cols="12" :md="justifySpaceBetween ? 'auto' : ''" class="" style="white-space: pre-line">
            <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                <slot />
            </div>
        </v-col>
        <template v-if="!noDevider">
            <v-col cols="12">
                <v-divider />
            </v-col>
        </template>
    </v-row>
</template>

<script>
export default {
    props: {
        term: String,
        required: Boolean,
        noDevider: Boolean,
        justifySpaceBetween: Boolean,
    },
};
</script>
